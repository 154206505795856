import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import axios from './api/http.js'; // 引入上面配置好的 axios 实例
// 挂载全局属性
let app=createApp(App)
app.config.globalProperties.$axios = axios;
app.use(router).use(ElementPlus).mount('#app')

// createApp(App).use(router).use(ElementPlus).mount('#app')
