// src/utils/axios.js
import axios from "axios";
import router from "../router/index";

// 创建 axios 实例
const service = axios.create({
  baseURL: "https://taokequan.top:8088", // 设置统一的请求前缀
  // baseURL: "http://127.0.0.1:9000",
  timeout: 5000, // 设置统一的超时时长
});

// 请求拦截器
service.interceptors.request.use(
  (config) => {
    let userdata = localStorage.getItem("userData");
    if (userdata != null) {
      config.headers["X-Access-Token"] = JSON.parse(userdata).token;
    }
    // 在这里可以添加一些请求前的操作，比如添加 Token 到 headers
    // config.headers['X-Access-Token'] = JSON.parse(localStorage.getItem('userData')).token;
    return config;
  },
  (error) => {
    // 请求错误处理
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    // 在这里可以添加一些响应后的操作，比如对返回数据的处理
    const res = response.data;
    // 根据返回的状态码进行一些操作，例如登录过期、错误提示等。
    if (res.code != 200 && res.code != 0) {
      // 业务错误处理，可以根据实际情况进行调整
      return Promise.reject(new Error(res.message || "Error"));
    } else {
      res.code=200
      return res;
    }
  },
  (error) => {
    console.log(error);
    if (error.response == null) {
      error.message = "网络错误";
    }else{
      error.message = error.response.data.message;
    }
    if (error.response.status == 401) {
      router.push("/login"); // 假设你的登录页面路由是 '/login'
    }
    // 响应错误处理
    return Promise.reject(error);
  }
);

export default service;
