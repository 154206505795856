import { createWebHistory, createRouter } from "vue-router";
// const authUrls = ['/member/mydata','/pay','/order','/mycart','/member/discount','/member/order']

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      // redirect:'/sy',
      name: "Home",
      component: () => import("@/view/home/home.vue"),
    },
    {
      path: "/Person",
      name: "Person",
      component: () => import("@/view/person/person.vue"),
    },
    {
      path: "/Person/baseinfo",
      name: "baseInfor",
      component: () => import("@/view/person/baseInfoChange.vue"),
    },
    {
      path: "/order",
      name: "order",
      component: () => import("@/view/order/order.vue"),
    },
    {
      path: "/phoneLogin",
      name: "phoneLogin",
      component: () => import("@/view/login/phoneLogin.vue"),
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/view/login/login.vue"),
    },
    {
      path: "/register",
      name: "register",
      component: () => import("@/view/login/register.vue"),
    },
  ],
});

// router.beforeEach((to,from,next)=>{
// //     const userStore = useUserStore();
// //     if(!authUrls.includes(to.path)){
// //         next()
// //     }
// //    else{
// //     if(!userStore.token) next('/login')
// //     else{
// //         if(to.path === '/pay' && from.path !== '/mycart') router.back(-1)
// //         else {
// //             next()
// //         }
// //     }
// //    }
// })
export default router;
