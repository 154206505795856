<template>
  <nav class="bottom-nav">
      <router-link to="/" class="nav-item" :class="{on:'/'==$route.path}">首页</router-link>
      <router-link to="/person" class="nav-item" :class="{on:'/person'==$route.path}">个人中心</router-link>
    </nav>
    <router-view></router-view>
</template>

<script>
import { ref } from "vue";
import router from "./router/index";
export default {
  setup() {
    console.log("开始运行")
    let userdata = localStorage.getItem("userData");
    if (userdata != null) {
      let token= JSON.parse(userdata).token;
      if(token==null ||token==undefined){
        router.push("/login");
      }
    }else{
      router.push("/login");
    }
    return {

    }
  },

  name: 'App',
  components: {
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 20px;
}
a{
  text-decoration:none;
}
.bottom-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-around;
  background-color: #f8f9fa;
  border-top: 1px solid #dee2e6;
  z-index: 999;
}
.on{
  color: rgb(0, 26, 255) !important;
}

.nav-item {
  padding: 1rem;
  text-align: center;
  color: #343a40;
  text-decoration: none;
}
</style>
